import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { MdMic, MdReplay, MdMicOff } from "react-icons/md";
import { IoMdExit } from "react-icons/io";

import interviewerImage from "../../../assets/img/interviewer/interviewer-avatar.gif";
import vibratorGif from "../../../assets/img/echo.gif";
import soundIndicatorGif from "../../../assets/img/sound-indicator.gif";
import startRecordingSound from "../../../assets/audio/startRecordingSound/system-notification-199277.mp3";
import stopRecordingSound from "../../../assets/audio/stopRecordingSound/pick-92276.mp3";

import axios from "axios";
import { app, getInterviewQuestions } from "controller/services/dbServices";
import { UserContext } from "controller/context/userContext";

interface LocationState {
  fullName?: string;
  jobTitle?: string;
  experience?: string;
  jobDescription?: string;
  interviewId?: string;
}

const InterviewPage: React.FC = () => {
  useParams();
  const { user } = React.useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  const { fullName, jobTitle, experience, jobDescription, interviewId } =
    (location.state || {}) as LocationState;

  const [answer, setAnswer] = useState<string>("");
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setAudioURL] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [audioContent, setAudioContent] = useState<string>("");
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const startRecordingAudioRef = useRef(new Audio(startRecordingSound));
  const stopRecordingAudioRef = useRef(new Audio(stopRecordingSound));

  const fetchQuestionAndAudio = async (candidateAnswer = "") => {
    try {
      setIsLoading(true);

      const response = await getInterviewQuestions(
        fullName || "",
        jobTitle || "",
        experience || "",
        jobDescription || "",
        interviewId || "",
        app.currentUser?.id || "",
        candidateAnswer
      );

      setQuestion(response.questions);
      setAudioContent(response.audioContent);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching question and audio:", error);
      toast.error("Failed to fetch question and audio.");
    }
  };

  useEffect(() => {
    fetchQuestionAndAudio();
    return () => {
      if (audioRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        audioRef.current.removeEventListener("ended", handleAudioEnded);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName, jobTitle, experience, jobDescription, interviewId]);

  useEffect(() => {
    if (audioContent && audioRef.current) {
      audioRef.current
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    }
  }, [audioContent]);

  const handleAudioEnded = () => {
    setIsAudioPlaying(false);
    handleRecord();
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", handleAudioEnded);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handlePlay = () => setIsAudioPlaying(true);
      const handlePause = () => setIsAudioPlaying(false);
      const handleEnded = () => {
        setIsAudioPlaying(false);
        handleRecord();
      };

      audioElement.addEventListener("play", handlePlay);
      audioElement.addEventListener("pause", handlePause);
      audioElement.addEventListener("ended", handleEnded);

      return () => {
        audioElement.removeEventListener("play", handlePlay);
        audioElement.removeEventListener("pause", handlePause);
        audioElement.removeEventListener("ended", handleEnded);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    const handlePopState = () => {
      window.history.pushState(null, "", window.location.href);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleRecord = async () => {
    if (isRecording) {
      mediaRecorderRef.current?.stop();
      setIsRecording(false);
      stopRecordingAudioRef.current.play();
    } else {
      startRecordingAudioRef.current.play();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = async (event) => {
        const audioBlob = event.data;
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        await transcribeAudio(audioBlob);
        handleNextQuestion();
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
    }
  };

  const transcribeAudio = async (audioBlob: Blob) => {
    setIsTranscribing(true);
    try {
      const formData = new FormData();
      formData.append("file", audioBlob, "audio.webm");

      const response = await axios.post(
        "https://transcribe.dev.coderythm.com/transcribe",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setAnswer(response.data.transcription);
    } catch (error) {
      console.error("Error transcribing audio:", error);
      toast.error("Failed to transcribe audio.");
    } finally {
      setIsTranscribing(false);
    }
  };

  const handleNextQuestion = async () => {
    await fetchQuestionAndAudio(answer);
    setAnswer("");
    setAudioURL("");
  };

  const handleReplayAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    }
  };

  const handleExitInterview = () => {
    navigate("/dashboard");
  };

  return (
    <div className="fixed left-0 top-0 grid h-screen w-screen grid-rows-[1fr_auto] overflow-hidden">
      <div className="grid grid-cols-2 border-b border-gray-700">
        {/* Interviewer Section */}
        <div
          className={`relative flex flex-col items-center justify-center p-6 ${
            isAudioPlaying ? "border-4 border-blue-500" : "border-transparent"
          }`}
          style={{ background: "black" }}
        >
          {isAudioPlaying && (
            <div className="absolute">
              <img
                src={vibratorGif}
                alt="Vibration Effect"
                className="h-62 w-62"
              />
            </div>
          )}
          <img
            src={interviewerImage}
            alt="Interviewer"
            className="z-10 h-32 w-32 rounded-full"
          />
          <p className="mt-4 text-center text-white">
            {isLoading
              ? "Loading next question..."
              : question || "Loading question..."}
          </p>

          {/* Sound Indicator */}
          {isAudioPlaying && (
            <div className="absolute right-2 top-2">
              <img
                src={soundIndicatorGif}
                alt="Sound Indicator"
                className="h-8 w-8"
              />
            </div>
          )}
        </div>

        {/* Candidate Section */}
        <div
          className={`relative flex flex-col items-center justify-center p-6 ${
            isRecording ? "border-4 border-blue-500" : "border-transparent"
          }`}
          style={{ background: "rgb(24 24 27)" }}
        >
          {isRecording && (
            <div className="absolute">
              <img
                src={vibratorGif}
                alt="Recording Indicator"
                className="h-62 w-62"
              />
            </div>
          )}
          <img
            src={user?.profile?.pictureUrl || "https://via.placeholder.com/150"}
            alt="Candidate"
            className="h-32 w-32 rounded-full"
          />
          <p className="mt-4 text-center text-yellow-400">
            {isTranscribing ? "Transcribing answer..." : answer}
          </p>
        </div>
      </div>

      {/* Footer Control Section */}
      <div
        className="flex items-center justify-center border-t border-gray-600 py-4"
        style={{ background: "black" }}
      >
        <button
          onClick={handleExitInterview}
          className="rounded-full bg-red-600 p-4"
          aria-label="Exit Interview"
        >
          <IoMdExit className="text-2xl text-white" />
        </button>
        <button
          onClick={handleRecord}
          className="mx-4 rounded-full bg-gray-700 p-4"
          aria-label="Record Audio"
        >
          {isRecording ? (
            <MdMicOff className="text-2xl text-red-600" />
          ) : (
            <MdMic className="text-2xl text-white" />
          )}
        </button>
        {audioContent && (
          <button
            onClick={handleReplayAudio}
            className="rounded-full bg-gray-700 p-4"
            aria-label="Replay Audio"
          >
            <MdReplay className="text-2xl text-white" />
          </button>
        )}
      </div>

      {audioContent && (
        <audio ref={audioRef} src={audioContent} className="hidden" />
      )}
      <ToastContainer />
    </div>
  );
};

export default InterviewPage;
