/* eslint-disable */

import { HiX } from "react-icons/hi";
import {
  Sidebar,
  Menu,
  MenuItem,
  MenuItemStyles,
  menuClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdOutlineWorkOutline,
  MdOutlineBusinessCenter,
  MdOutlineLogout,
} from "react-icons/md";
import React from "react";
import { UserContext } from "controller/context/userContext";

const SidebarContainer = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
  toggled: boolean;
  setToggled: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, toggled, setToggled } = props;
  const menuItemStyles: MenuItemStyles = {
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({}),
    button: {
      [`&.${menuClasses.disabled}`]: {},
      "&:hover": {},
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const { logOutUser } = React.useContext(UserContext);
  const [openSubMenu, setOpenSubMenu] = React.useState(null);

  const handleSubMenuClick = (menuKey: string) => {
    if (openSubMenu === menuKey) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(menuKey);
    }
  };

  return (
    <Sidebar collapsed={open} toggled={toggled} breakPoint="md">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "space-between",
          padding: "20px 0",
        }}
        className="bg-white dark:!bg-navy-800 dark:text-white"
      >
        <div>
          <div className={`mx-[20px] mb-[50px] mt-[30px] flex items-center`}>
            <span
              className="absolute right-4 top-4 block cursor-pointer md:hidden"
              onClick={setToggled}
            >
              <HiX />
            </span>
            {!open && (
              <div className="ml-1 mt-1 h-2.5 font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-white">
                <span className="font-medium">EDISON AI</span>
              </div>
            )}
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<MdOutlineDashboard className="h-6 w-6" />}
              className="text-lg font-semibold hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/default" />}
              onClick={() => handleSubMenuClick(null)}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              icon={<MdOutlineWorkOutline className="h-6 w-6" />}
              className="text-lg font-semibold hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/jobs" />}
              onClick={() => handleSubMenuClick(null)}
            >
              Jobs
            </MenuItem>
            <MenuItem
              icon={<MdOutlineBusinessCenter className="h-6 w-6" />}
              className="text-lg font-semibold hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to="/admin/about-company" />}
              onClick={() => handleSubMenuClick(null)}
            >
              About Company
            </MenuItem>
          </Menu>
        </div>
        <div className="mb-4 flex justify-center">
          <button
            className="flex items-center gap-2 rounded-full bg-gray-800 px-4 py-2 text-white"
            onClick={logOutUser}
          >
            <MdOutlineLogout className="h-5 w-5" />
            Logout
          </button>
        </div>
      </div>
    </Sidebar>
  );
};

export default SidebarContainer;
