import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import interviewIllustration from "../../../assets/svg/interview.svg";

const StartInterview: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fullName, jobTitle, experience, jobDescription, interviewId } =
    location.state || {};

  const startInterview = () => {
    navigate(`/admin/interview/${interviewId}`, {
      state: {
        fullName,
        jobTitle,
        experience,
        jobDescription,
        interviewId,
        interviewTime: "10 min",
      },
    });
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 p-8">
      <div className="flex max-w-5xl flex-col items-center justify-between md:flex-row">
        <div className="flex-1">
          <h1 className="text-4xl font-bold leading-tight text-gray-800">
            The Best Virtual Interviewing for Everyone
          </h1>
          <div className="mt-6 rounded-lg border border-gray-200 p-6 shadow-sm">
            <p className="text-xl font-semibold" style={{ color: "black" }}>
              Ready for your interview,{" "}
              <span className="font-bold" style={{ color: "black" }}>
                {fullName}
              </span>
              ?
            </p>
            <p className="mt-4 text-lg text-gray-600">
              <strong>Job Title:</strong> {jobTitle}
            </p>
            <p className="mt-2 text-lg text-gray-600">
              <strong>Experience:</strong> {experience} years
            </p>
            <p className="mt-2 text-lg text-gray-600">
              <strong>Description:</strong> {jobDescription}
            </p>
          </div>
          <button
            className="mt-8 rounded-lg bg-blue-600 px-10 py-4 text-lg font-bold text-white transition duration-200 hover:bg-blue-700"
            onClick={startInterview}
          >
            Start Interview
          </button>
        </div>

        <div className="mt-8 flex flex-1 justify-center md:mt-0">
          <div className="rounded-full border border-gray-300 p-4">
            <img
              src={interviewIllustration}
              alt="Interview Illustration"
              className="h-80 w-80 object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartInterview;
