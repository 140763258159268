import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "../../controller/services/dbServices"; // Update with your actual path
import { UserContext } from "../../controller/context/userContext"; // Update with your actual path
import * as Realm from "realm-web";

const AuthRedirect = () => {
  const navigate = useNavigate();
  const { setUser } = React.useContext(UserContext);

  useEffect(() => {
    const completeLogin = async () => {
      try {
        await Realm.handleAuthRedirect();

        if (app.currentUser) {
          setUser(app.currentUser);
          navigate("/admin");
        }
      } catch (error) {
        console.error("Failed to complete OAuth login:", error);
        navigate("/auth/sign-in");
      }
    };

    completeLogin();
  }, [navigate, setUser]);

  return <div>Loading...</div>;
};

export default AuthRedirect;
