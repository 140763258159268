import Card from "components/card";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useUsers } from "./useUsers";
import React from "react";

const AddUsers = () => {
  const {
    loading,
    initialValues,
    validationSchema,
    onSubmit,
    id,
    userData,
    isSuccess,
  } = useUsers();

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            {/* <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Notifications
            </h4> */}
            <div className="px-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setValues }) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  React.useEffect(() => {
                    if (id && userData) {
                      setValues({
                        name: userData.name,
                        email: userData.email,
                        password: userData.password,
                        confirmPassword: userData.password,
                      });
                    }
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                  }, [id, isSuccess, userData]);
                  return (
                    <Form>
                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Email*
                        </label>
                        <Field
                          type="email"
                          name="email"
                          readOnly={id}
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Name*
                        </label>
                        <Field
                          type="name"
                          name="name"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      {/* Password */}
                      {!id ? (
                        <div>
                          <div className="mb-3">
                            <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                              Password*
                            </label>
                            <Field
                              type="password"
                              name="password"
                              className={
                                "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                              Confirm Password*
                            </label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              className={
                                "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                              }
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="mb-5 mt-5 text-sm text-gray-600 dark:text-gray-400">
                            <p className="mb-3 text-base text-navy-700 dark:text-white">
                              Password must contain:
                            </p>
                            <ul className="mb-2 list-disc space-y-1 pl-5 text-navy-700 dark:text-white">
                              <li>At least 8 characters</li>
                              <li>At least one uppercase letter (A-Z)</li>
                              <li>At least one lowercase letter (a-z)</li>
                              <li>At least one number (0-9)</li>
                              <li>
                                At least one special character (@, $, !, %, *,
                                #, ?, &)
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : null}

                      {/* Checkbox */}
                      <button
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        // onClick={() => navigate("/auth")}
                        type="submit"
                      >
                        {loading ? `Saving...` : `Save`}
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddUsers;
