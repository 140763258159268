import ActiveJobs from "components/activeJobs/ActiveJobs";
import { UserContext } from "controller/context/userContext";
import React from "react";
import { IoCloudUploadOutline, IoCheckmarkDoneOutline } from "react-icons/io5";

const Dashboard = () => {
  const { user } = React.useContext(UserContext);

  return (
    <div className="p-6">
      <div
        className="mb-6 flex flex-col items-center justify-center rounded-lg p-8 text-white"
        style={{
          background:
            "linear-gradient(90deg, rgba(103, 80, 248, 1) 0%, rgba(124, 58, 237, 1) 100%)",
        }}
      >
        <h2 className="text-2xl font-semibold">{`Hello ${user?.profile?.data?.name}`}</h2>
        <h2 className="text-2xl font-semibold">Welcome to Edison</h2>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
        <ActiveJobs organizationId={"66d8a7b2f8bc7c05c1321944"} />
      </div>

      <div className="mt-8 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div className="flex items-center rounded-lg bg-white p-12 shadow">
          <IoCloudUploadOutline className="mr-4 text-2xl text-gray-600" />
          <h3 className="text-lg font-medium">Uploaded Resumes</h3>
        </div>
        <div className="flex items-center rounded-lg bg-white p-6 shadow">
          <IoCheckmarkDoneOutline className="mr-4 text-2xl text-gray-600" />
          <h3 className="text-lg font-medium">Interviews Attended</h3>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
