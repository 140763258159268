import React from "react";

const AboutCompany = () => {
  const companyDetails = {
    name: "Coderythm",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo vulputate dolor eget tincidunt. Praesent efficitur nulla vel lobortis cursus. Mauris sit amet lectus ac tellus tincidunt placerat in efficitur lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo vulputate dolor eget tincidunt. Praesent efficitur nulla vel lobortis cursus. Mauris sit amet lectus ac tellus tincidunt placerat in efficitur lacus.",
    logoUrl: "https://via.placeholder.com/100",
    otherDetails: [
      {
        title: "Other Details",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo vulputate dolor eget tincidunt. Praesent efficitur nulla vel lobortis cursus. Mauris sit amet lectus ac tellus tincidunt placerat in efficitur lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo vulputate dolor eget tincidunt. Praesent efficitur nulla vel lobortis cursus. Mauris sit amet lectus ac tellus tincidunt placerat in efficitur lacus.",
      },
      {
        title: "Other Details",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo vulputate dolor eget tincidunt. Praesent efficitur nulla vel lobortis cursus. Mauris sit amet lectus ac tellus tincidunt placerat in efficitur lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi commodo vulputate dolor eget tincidunt. Praesent efficitur nulla vel lobortis cursus. Mauris sit amet lectus ac tellus tincidunt placerat in efficitur lacus.",
      },
    ],
  };

  return (
    <div className="p-6">
      <div className="mb-6 flex flex-col rounded-lg bg-white p-6 shadow-md md:flex-row md:justify-between">
        <div className="flex-1">
          <h3 className="mb-4 text-xl font-semibold">About the Company</h3>
          <h4 className="mb-2 text-lg font-medium">{companyDetails.name}</h4>
          <p className="text-sm text-black-600">{companyDetails.description}</p>
        </div>
        <div className="mt-4 flex-none md:mt-0">
          <img
            src={companyDetails.logoUrl}
            alt="Company Logo"
            className="h-24 w-24 rounded-full"
          />
        </div>
      </div>

      {companyDetails.otherDetails.map((detail, index) => (
        <div key={index} className="mb-6 rounded-lg bg-white p-6 shadow-md">
          <h4 className="mb-2 text-lg font-medium">{detail.title}</h4>
          <p className="text-black-600 text-sm">{detail.content}</p>
        </div>
      ))}
    </div>
  );
};

export default AboutCompany;
