import { FormData } from "types/jobApplicationModalTypes";

export const populateFormData = (extractedText: string): FormData => {
  const lines = extractedText.split("\n");
  const data: Record<string, string> = {};

  lines.forEach((line) => {
    const [key, value] = line.split(":").map((str) => str.trim());
    if (key && value) {
      data[key.replace(/\s+/g, "")] = value;
    }
  });

  return {
    fullName: data.FullName || "",
    jobTitle: data.JobTitle || "",
    experience: data.Experience || "",
    jobDescription: data.JobDescription || "",
  };
};
