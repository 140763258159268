import { useNavigate } from "react-router-dom";
import { useGetJobsByOrganization } from "model/queryCalls/queryCalls";

const ActiveJobs = ({ organizationId }: { organizationId: string }) => {
  const navigate = useNavigate();

  const {
    data: jobs = [],
    isLoading,
    isError,
  } = useGetJobsByOrganization(organizationId);

  const handleJobClick = (jobId: string) => {
    navigate(`/admin/job/${jobId}`);
  };

  if (isLoading) {
    return <div>Loading jobs...</div>;
  }

  if (isError) {
    return <div>Failed to load jobs.</div>;
  }

  return (
    <div className="col-span-1 rounded-lg bg-white p-6 shadow md:col-span-2 xl:col-span-3">
      <h3 className="mb-4 text-xl font-medium">Active Jobs</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                Title
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                Location
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500">
                Salary
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {jobs.map((job: any) => (
              <tr
                key={job._id}
                className="hover:bg-gray-50"
                onClick={() => handleJobClick(job._id)}
              >
                <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                  {job.title}
                </td>
                <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                  {job.location}
                </td>
                <td className="text-black-500 whitespace-nowrap px-6 py-4 text-sm">
                  {job.salary}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveJobs;
