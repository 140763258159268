import React from "react";
import Dropdown from "components/dropdown";
import { Avatar } from "@mui/material";
import { UserContext } from "controller/context/userContext";

const Navbar = (props: {
  onOpenSidenav: () => void;
  ontoggleSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const [, setIsLargeScreen] = React.useState(window.innerWidth > 768);
  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="flex flex-row gap-2">
        <div className="ml-[6px]">
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white"></p>
        </div>
      </div>

      <div className="relative mt-[3px] flex h-[61px] items-center gap-4">
        <div className="flex flex-col items-start">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {user?.profile?.data?.name || "User Name"}
          </p>
          <p className="text-xs text-gray-500 dark:text-white/70">
            {user?.profile?.data?.email || "user@example.com"}
          </p>
        </div>
        <Dropdown
          button={
            <Avatar
              alt={user?.profile?.data?.name}
              src={user?.profile?.data?.pictureUrl || ""}
              sx={{ width: 60, height: 60 }}
            />
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
          children={undefined}
        />
      </div>
    </nav>
  );
};

export default Navbar;
