import { JobApplicationModal } from "components/jobApplicationModal/jobApplicationModal";
import { useGetJobById } from "model/queryCalls/queryCalls";
import { useState } from "react";
import { useParams } from "react-router-dom";

const JobDescription = () => {
  const { id: jobId } = useParams<{ id: string }>();
  const { data: job, isLoading, isError } = useGetJobById(jobId!);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleApplyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <div>Loading job details...</div>;
  }

  if (isError || !job) {
    return <div>Failed to load job details</div>;
  }

  return (
    <div className="min-h-screen p-6">
      <div className="flex flex-col items-start justify-between space-y-4 rounded-lg bg-white p-6 shadow md:flex-row md:items-center md:space-y-0">
        <div className="w-full md:w-2/3">
          <h2 className="text-2xl font-bold">{job.companyName}</h2>
          <p className="mt-2 text-gray-900">
            {job.aboutCompany || "No description available."}
          </p>
          <button
            className="mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white"
            onClick={handleApplyClick}
          >
            Apply Now
          </button>
        </div>
        <div className="flex w-full justify-center md:w-auto md:justify-end">
          <img
            src={job.companyLogo}
            alt="Company Logo"
            className="h-20 w-20 rounded-full object-cover"
          />
        </div>
      </div>

      <div className="mt-6 rounded-lg bg-white p-6 shadow">
        <h3 className="text-xl font-bold">Job Description</h3>
        <div className="mt-8">
          <h4 className="text-lg font-semibold">Key Role Related Roles:</h4>
          <ul className="mt-2 list-disc pl-5">
            {job.relatedRoles.map((role, index) => (
              <li key={index} className="text-gray-900">
                {role}
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-4">
          <h4 className="text-lg font-semibold">Role Objective:</h4>
          <p className="mt-2 text-gray-900">{job.objective}</p>
        </div>

        <div className="mt-4">
          <h4 className="text-lg font-semibold">Role Responsibilities:</h4>
          <ul className="mt-2 list-disc pl-5">
            {job.responsibilities.map((resp, index) => (
              <li key={index} className="text-gray-900">
                {resp}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="rounded-lg bg-white p-6 shadow">
          <h3 className="text-lg font-bold">Salary Details</h3>
          <ul className="mt-2 list-disc pl-5">
            {job.salaryDetails.map((detail, index) => (
              <li key={index} className="text-gray-900">
                {detail}
              </li>
            ))}
          </ul>
        </div>

        <div className="rounded-lg bg-white p-6 shadow">
          <h3 className="text-lg font-bold">Other Details</h3>
          <ul className="mt-2 list-disc pl-5">
            {job.otherDetails.map((detail, index) => (
              <li key={index} className="text-gray-900">
                {detail}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <JobApplicationModal isOpen={isModalOpen} onClose={handleCloseModal} />

      <div className="mt-6 flex justify-center">
        <button
          className="max-w-screen w-full rounded-lg bg-blue-600 px-6 py-3 text-xl font-bold text-white"
          onClick={handleApplyClick}
        >
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default JobDescription;
