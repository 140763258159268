import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import AdminLayout from "layouts/admin";
import { UserContext } from "controller/context/userContext";
import SignIn from "views/auth/SignIn";
import AuthRedirect from "views/auth/authRedirect";

const App = () => {
  const { user, loading } = useContext(UserContext);
  const queryClient = new QueryClient();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/admin/*"
          element={user ? <AdminLayout /> : <Navigate to="/auth/sign-in" replace />}
        />
        <Route path="/auth/sign-in" element={<SignIn />} />
        <Route path="/auth/redirect" element={<AuthRedirect />} />
        <Route
          path="/"
          element={<Navigate to={user ? "/admin" : "/auth/sign-in"} replace />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
