import { app } from "controller/services/dbServices";
import React, { createContext, useState, useEffect } from "react";
import * as Realm from "realm-web";

export const UserContext = createContext<any>(null);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<Realm.User | null>(null);
  const [loading, setLoading] = useState(true);

  const googleLogin = async () => {
    const credentials = Realm.Credentials.google({
      redirectUrl: `${window.location.origin}/auth/redirect`,
    });

    try {
      const authUser = await app.logIn(credentials);
      setUser(authUser);
      localStorage.setItem("realmUser", JSON.stringify(authUser));

      return authUser;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const rehydrateUserSession = () => {
    const savedUser = localStorage.getItem("realmUser");
    if (savedUser) {
      const userObject = JSON.parse(savedUser);
      setUser(userObject);
    } else if (app.currentUser) {
      setUser(app.currentUser);
    }
    setLoading(false);
  };

  useEffect(() => {
    rehydrateUserSession();
  }, []);

  const logOutUser = async () => {
    try {
      await app.currentUser?.logOut();
      setUser(null);
      localStorage.removeItem("realmUser");
    } catch (error) {
      console.error("Failed to log out user:", error);
    }
  };

  return (
    <UserContext.Provider value={{ user, googleLogin, logOutUser, loading }}>
      {children}
    </UserContext.Provider>
  );
}
